import {
  mobile,
  backend,
  creator,
  web,
  react,
  reactjs,
  mongodb,
  git,
  threejs,
  vue,
  next,
  mysql,
  express,
  graphql,
  aws,
  d3js,
  nest,
  docker,
  cogmento,
  goons,
  humming,
  proper,
  diyar,
  travel,
  vikid,
  rent,
  angular,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "experience",
    title: "Experience",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Frontend Engineer",
    icon: creator,
  },
  {
    title: "Web Developer",
    icon: web,
  },
  {
    title: "React Developer",
    icon: react,
  },
  {
    title: "UI/UX Developer",
    icon: backend,
  },
];

const technologies = [
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Next",
    icon: next,
  },
  {
    name: "Vue",
    icon: vue,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "Angular",
    icon: angular,
  },
  {
    name: "GraphQL",
    icon: graphql,
  },
  {
    name: "AWS",
    icon: aws,
  },
  {
    name: "D3.js",
    icon: d3js,
  },
  {
    name: "Git",
    icon: git,
  },
  {
    name: "Docker",
    icon: docker,
  },
];

const experiences = [
  {
    title: "Front-End Engineer",
    company_name: "Kyra Solutions, Inc",
    icon: "/company/kyra.jpeg",
    iconBg: "rgb(230, 222, 221)",
    date: "May 2022 - Jan 2025",
    points: [
      "Led frontend development on enterprise-grade digital solutions for government agencies, modernizing legacy systems and improving real-time data processing while mastering Angular 15 and NgRx.",
      "Reduced initial JavaScript payload with lazy loading and decreased total bundle size by 50% using Webpack tree-shaking, leading to a 2.3s improvement in Largest Contentful Paint (LCP).",
      "Optimized NgRx state management, eliminating redundant updates and improving UI responsiveness by hundreds of milliseconds per interaction.",
      "Improved API efficiency by integrating GraphQL, reducing network payload size by over 30% and minimizing unnecessary data fetching.",
      "Used WebSockets for real-time updates while cutting down network requests by thousands per day.",
      "Developed an interactive analytics dashboard with D3.js, improving large dataset rendering speed by over 50%, ensuring smooth performance for users.",
      "Refactored and modularized UI components, reducing code duplication by over 30% and accelerating feature development.",
      "Developed a shared UI component library, standardizing UI consistency across projects and reducing frontend build time for new features.",
      "Led frontend integration efforts with backend engineers and DevOps teams, improving API performance and optimizing CI/CD pipelines, reducing deployment times by 50%.",
      "Mentored junior developers, reducing onboarding time by around 40%, improving knowledge transfer, and strengthening Angular best practices.",
      "Led daily code reviews and knowledge-sharing sessions, improving frontend code quality and consistency across multiple teams.",
      "Worked cross-functionally with UX designers, backend engineers, and project managers to deliver high-impact, scalable frontend solutions.",
    ],
  },
  {
    title: "Frontend Developer",
    company_name: "intrinio",
    icon: "/company/intrinio.jpeg",
    iconBg: "rgb(230, 222, 221)",
    date: "Jul 2019 - Apr 2021",
    points: [
      "Transitioned from Vue.js to React.js, adapting to the scalability needs of fintech applications while mastering Redux, WebSockets, and GraphQL.",
      "Engineered a real-time stock analytics dashboard, integrating React.js, Redux, WebSockets, and D3.js to provide live financial data visualization for traders and analysts.",
      "Optimized API performance by implementing GraphQL queries, batch requests, and caching strategies, reducing API load by 30% and improving data retrieval speed.",
      "Built interactive financial charts with D3.js, enabling traders to zoom, pan, and overlay custom financial indicators.",
      "Implemented real-time stock updates, replacing API polling with WebSockets, ensuring instant price synchronization across multiple user sessions.",
      "Delivered high-performance UI components under strict deadlines, reducing page load times by 50% using React Virtualized, lazy-loading, and memoization techniques.",
      "Collaborated with backend engineers, data scientists, and UI/UX designers to create an intuitive, high-speed financial analytics platform.",
    ],
  },
  {
    title: "Web Developer",
    company_name: "Fizen",
    icon: "/company/fizen.png",
    iconBg: "rgb(56, 62, 86)",
    date: "Jan 2017 - Jun 2019",
    points: [
      "Transitioned from backend engineering to frontend, mastering Vue.js, JavaScript (ES6+), and state management (Vuex).",
      "Built data visualization dashboards using Chart.js, improving reporting efficiency for business teams.",
      "Integrated RESTful APIs and implemented real-time WebSocket updates, enhancing data interactions.",
      "Refactored and modularized Vue components, increasing reusability and improving frontend maintainability by 30%.",
      "Collaborated with designers to improve UI/UX, leading to a 20% increase in session duration and click-through rates while reducing bounce rate by 10% (based on Google Analytics data).",
      "Worked closely with backend engineers to ensure efficient API consumption and performance tuning.",
      "Contributed to code reviews and mentored junior developers, reinforcing best practices in Vue development.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Alex proved me wrong.",
    name: "Rajesh Venugopal",
    designation: "Direct of Engineering",
    company: "Ingram Micro",
    image: "/CTOs/7.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Alex does.",
    name: "Chris Brown",
    designation: "Project Manager",
    company: "Dapper Labs",
    image: "/CTOs/5.jpg",
  },
  {
    testimonial:
      "After Alex optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Alexei Gura",
    designation: "CTO",
    company: "America's Frontline Doctors",
    image: "/CTOs/3.jpg",
  },
];

const projects = [
  {
    name: "Cogmento CRM",
    description:
      "This platform offers a comprehensive CRM solution that helps businesses manage customer relationships, sales, and marketing efforts through a user-friendly interface and various tools.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Django",
      //   color: "green-text-gradient",
      // },
    ],
    image: cogmento,
    site_link: "https://cogmento.com",
  },
  {
    name: "Goons of Balatroon",
    description:
      "This website showcases an NFT card game, featuring gameplay mechanics and community engagement through various interactive elements and events.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Node",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "Web3",
      //   color: "pink-text-gradient",
      // },
      // {
      //   name: "IMX",
      //   color: "green-text-gradient",
      // },
    ],
    image: goons,
    site_link: "https://goonsofbalatroon.com",
  },
  {
    name: "HummingBot.io",
    description:
      "Hummingbot is an open-source platform that allows users to build and run cryptocurrency trading bots, providing tools for algorithmic trading and liquidity mining across different exchanges.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Gatsby",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "Web3",
      //   color: "pink-text-gradient",
      // },
      // {
      //   name: "PixiJS",
      //   color: "green-text-gradient",
      // },
    ],
    image: humming,
    site_link: "https://hummingbot.io",
  },
  {
    name: "Properstar Estate",
    description:
      "Properstar is a property rental platform that connects users with real estate listings, offering a variety of properties for rent and sale in different locations, along with helpful search features.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Express",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "Contentful",
      //   color: "pink-text-gradient",
      // },
    ],
    image: proper,
    site_link: "https://www.properstar.com",
  },
  {
    name: "Diyar News",
    description:
      "This real estate development in Bahrain features a master-planned community with residential, commercial, and recreational spaces designed to promote a luxurious lifestyle.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Gatsby",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "Contentful",
      //   color: "pink-text-gradient",
      // },
    ],
    image: diyar,
    site_link: "https://www.diyar.bh",
  },
  // {
  //   name: "Traveling Girls",
  //   description:
  //   "TravelGirls is a travel companion website that connects men with women looking for travel partners, focusing on romantic connections and shared travel experiences.",
  //     tags: [
  //       {
  //         name: "Angular",
  //         color: "blue-text-gradient",
  //       },
  //       {
  //         name: "Node.js",
  //         color: "green-text-gradient",
  //       }
  //     ],
  //   image: travel,
  //   site_link: "https://www.travelgirls.com",
  // },
  {
    name: "Vikids Women",
    description:
      "This site offers parenting and child development resources, providing advice on topics like adolescent relationships and children's poetry to support parents and educators.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Ruby",
      //   color: "green-text-gradient",
      // },
      // {
      //   name: "Medium",
      //   color: "pink-text-gradient",
      // },
    ],
    image: vikid,
    site_link: "https://www.vikids.ru",
  },
  {
    name: "Rent Furnished",
    description:
      "Wunderflats is an online platform for renting furnished apartments in Germany, designed to help tenants find temporary housing while offering landlords a way to connect with verified renters.",
    tags: [
      // {
      //   name: "React",
      //   color: "blue-text-gradient",
      // },
      // {
      //   name: "Node",
      //   color: "green-text-gradient",
      // }
    ],
    image: rent,
    site_link: "http://wunderflats.com",
  },
];

export { services, technologies, experiences, testimonials, projects };
